import { useState ,useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link, NavLink } from "react-router-dom";
import logoWandachowicz from '../../img/wandachowicz.svg';
import logoCom from '../../img/com.svg';
import logoSignet from '../../img/logoSignet.svg';
import LanguageSwitcher from "../LanguageSwitcher";

export function Header() {
  const { t, i18n } = useTranslation();
  const isEnglish = i18n.language === 'en';
  const [logoVisible, setLogoVisible] = useState(true);
  const [isNavOpen, setIsNavOpen] = useState(false); // Stan śledzący, czy menu jest otwarte
  const [bottomOffset, setBottomOffset] = useState('0px'); // Dodany stan dla offsetu
  const mainNavRef = useRef(null);
  const mainNavButton = () => {
    setIsNavOpen(!isNavOpen);
    const mainNav = mainNavRef.current;
    if (mainNav) {
      mainNav.classList.toggle('open');
      // document.body.classList.toggle('noScroll');
    }
  }
  isNavOpen ? document.body.classList.add('noScroll') : document.body.classList.remove('noScroll');

  useEffect(() => {
    // Funkcja do wykrywania przeglądarki
    const detectBrowser = () => {
      const userAgent = navigator.userAgent;

      if (/CriOS/i.test(userAgent)) {
        return "Chrome";
      } else if (/FxiOS/i.test(userAgent)) {
        return "Firefox";
      } else if (/Safari/i.test(userAgent) && !/Chrome/i.test(userAgent)) {
        return "Safari";
      } else {
        return "Other";
      }
    };

    const browser = detectBrowser();

    // Ustawienie offsetu w zależności od przeglądarki
    if (browser === 'Chrome') {
      setBottomOffset('120px');
    } else if (browser === 'Firefox') {
      setBottomOffset('30px');
    } else if (browser === 'Safari') {
      setBottomOffset('100px');
    } else {
      setBottomOffset('0px');
    }
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;
        setLogoVisible(entry.isIntersecting);
      },
      {
        rootMargin: '0px',
        threshold: 1
      }
    );

    const heroSection = document.querySelector('header'); // ID sekcji, która wyzwala zmianę
    if (heroSection) observer.observe(heroSection);

    // Funkcja czyszczenia
    return () => {
      if (heroSection) observer.unobserve(heroSection);
    };
  }, []);

  return (
    <>
      <nav id="mainNav" ref={mainNavRef}>
        <LanguageSwitcher />
        <ul id="nav">
          <li><NavLink to={isEnglish ? "/en/work" : "/praca"} activeclassname="active">{t('linkToWork')}</NavLink></li>
          <li><NavLink to={isEnglish ? "/en/about" : "/o-mnie"} activeclassname="active">{t('linkToAbout')}</NavLink></li>
          <li><NavLink to={isEnglish ? "/en/after-work" : "/po-pracy"} activeclassname="active">{t('linkToAfterWork')}</NavLink></li>
          <li><NavLink to={isEnglish ? "/en/contact" : "/kontakt"} activeclassname="active">{t('linkToContact')}</NavLink></li>
        </ul>

        <div id="social" style={{ bottom: bottomOffset }}>
          <a href="https://www.linkedin.com/in/wandachowicz/" target="_blank" rel="noopener noreferrer"><img src="/img/social/linkedin.svg" alt={t('altToLinkedin')} /></a>
          <a href="https://www.behance.net/wandachowicz" target="_blank" rel="noopener noreferrer"><img src="/img/social/behance.svg" alt={t('altToBehance')} /></a>
          <a href="https://dribbble.com/wandachowicz" target="_blank" rel="noopener noreferrer"><img src="/img/social/dribbble.svg" alt={t('altToDribbble')} /></a>
          <a href="https://www.instagram.com/wandachowicz_com/" target="_blank" rel="noopener noreferrer"><img src="/img/social/instagram.svg" alt={t('altToInstagram')} /></a>
          <a href="https://github.com/chrisowicz" target="_blank" rel="noopener noreferrer"><img src="/img/social/github.svg" alt={t('altToGitHub')} /></a>
        </div>
      </nav>
      <header>
        <Link id='logo' to='/' className={isNavOpen ? 'navOpen' : undefined}>
          <img className={!logoVisible ? 'logoSignet logo-move-left' : 'logoSignet'} src={logoSignet} alt={t('altToLogo')} width={'103.1'} height={'93.6'} />
          <img className={!logoVisible ? 'logoOwicz logo-fade-out' : 'logoOwicz'} src={logoWandachowicz} alt={t('altToLogo')} width={'236.3'} height={'25.5'} />
          <img className={!logoVisible ? 'logoCom logo-fade-out' : 'logoCom'} src={logoCom} alt={t('altToLogo')} width={'81.6'} height={'35.4'} />
        </Link>
        <button onClick={mainNavButton} id="navBarToggle" className={isNavOpen ? 'close' : ''} aria-controls="maiNav" aria-expanded={isNavOpen}>
          <i id="toggle"></i>
        </button>
      </header>
    </>
  )
}